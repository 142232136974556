import { useDjangoQuery } from "./useDjangoQuery";

type FeatureFilter = {
  layer: number;
  typeId: number;
  filterFieldName: string;
};

export type ContextualLayer = {
  name: string;
  service: string;
  base_url: string;
  feature_filter: FeatureFilter | null;
};

export type ContextualLayerGroup = {
  name: string;
  layers: ContextualLayer[];
};
type ContextualLayers = ReadonlyArray<ContextualLayerGroup>;

export const useContextualLayersQuery = () =>
  useDjangoQuery<ContextualLayers>("/api/catalog/contextual/");
