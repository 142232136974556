import "./MapControls.scss";
import { useState, useMemo, useEffect } from "react";
import { ReactComponent as LocationIcon } from "assets/sidebar-icons/search.svg";
import { ReactComponent as CalendarIcon } from "assets/other-icons/calendar.svg";
import Collapse from "react-bootstrap/Collapse";
import { useMap } from "react-leaflet";
import {
  Panel,
  PanelVisibility,
  usePanels,
} from "components/panel/PanelsProvider";
import { MapFilterControlsHeaderButton } from "./MapFilterControlsHeaderButton";
import { LocationControl } from "./LocationControl";
import { DateRangeControl } from "./DateRangeControl";

export type Dropdown =
  | "date-range"
  | "time-slider"
  | "location"
  | "quick-search"
  | "";

export const MapFilterControls = () => {
  const [openDropdown, setOpenDropdown] = useState<Dropdown>("");

  const [isMapActive, setIsMapActive] = useState(true); // disable keyboard during map controls to allow address entering
  const map = useMap();
  const { panels } = usePanels();

  const small = useMemo(() => {
    const panelWidth = 448; // width of a panel
    const expandedPanels = panels.filter(
      (p: Panel) => p.visibility === PanelVisibility.Expanded
    );
    const expandedPanelsWidth = (expandedPanels.length + 0.5) * panelWidth; // include half a panel worth of extra content
    return window.innerWidth - expandedPanelsWidth < panelWidth;
  }, [panels]);

  useEffect(() => {
    // close expanded map control when viewport gets too small
    if (small) {
      setOpenDropdown("");
    }
  }, [small]);

  useEffect(() => {
    if (isMapActive) {
      map.dragging.enable();
      map.keyboard.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
    } else {
      map.dragging.disable();
      map.keyboard.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
    }
  }, [isMapActive, map]);

  const content = {
    location: <LocationControl setIsMapActive={setIsMapActive} />,
    "date-range": <DateRangeControl />,
    "time-slider": null,
    "quick-search": null,
    "": null,
  }[openDropdown];

  return (
    <div
      className="map-controls"
      onMouseOver={() => setIsMapActive(false)}
      onMouseOut={() => setIsMapActive(true)}
    >
      <div className="d-flex gap-3 justify-content-end">
        <MapFilterControlsHeaderButton
          id="location"
          title="Location"
          small={small}
          active={openDropdown === "location"}
          icon={<LocationIcon width={26} height={26} />}
          onClick={() =>
            setOpenDropdown(openDropdown === "location" ? "" : "location")
          }
        />
        <MapFilterControlsHeaderButton
          id="date-range"
          title="Dates"
          small={small}
          active={openDropdown === "date-range"}
          icon={<CalendarIcon width={18} height={22} />}
          onClick={() =>
            setOpenDropdown(openDropdown === "date-range" ? "" : "date-range")
          }
        />
      </div>

      <Collapse in={openDropdown !== ""}>
        <div className="map-controls-content p-2 my-3 rounded-3">{content}</div>
      </Collapse>
    </div>
  );
};
