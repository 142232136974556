import { createContext, useState, useContext, useCallback } from "react";
import { SearchResults } from "pages/root/Panel/Search/SearchForm";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { MyOrganisationFilterValue } from "pages/root/Panel/MyOrganisation/MyOrganisationFilterContext";
import { useMyOrganisation } from "components/fetch/useMyOrganisation";
import { myOrganisationsFilterToParams } from "components/fetch/useProjectsQuery";
import { projectsPerPage } from "components/project/pagination/PaginationNavigation";

export enum MyOrganisationsProjectsSearchStatus {
  NeverRun = "NeverRun",
  Inactive = "Inactive",
  InProgress = "InProgress",
}

interface MyOrganisationsProjectsContextInterface {
  // State:
  currentPageResults: SearchResults | null;
  searchParams: FormData;
  myOrganisationsProjectsSearchStatus: MyOrganisationsProjectsSearchStatus;

  // Functions:
  searchWithFilter: (filter: MyOrganisationFilterValue) => void;
  searchWithPageNumber: (pageNumber: number) => void;
  reset: () => void;
}

// Todo: Extract shared logic with SearchedProjectsContext into helper functions
export const MyOrganisationsProjectsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentPageResults, setCurrentPageResults] =
    useState<SearchResults | null>(null);
  const [searchParams, setSearchParams] = useState<FormData>(new FormData());
  const [
    myOrganisationsProjectsSearchStatus,
    setMyOrganisationsProjectsSearchStatus,
  ] = useState<MyOrganisationsProjectsSearchStatus>(
    MyOrganisationsProjectsSearchStatus.NeverRun
  );
  const { search } = useDjangoApi();

  const reset = useCallback(() => {
    setCurrentPageResults(null);
  }, []);

  const searchWithParams = useCallback(
    (params: FormData) => {
      if (
        myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.InProgress
      )
        return;
      setMyOrganisationsProjectsSearchStatus(
        MyOrganisationsProjectsSearchStatus.InProgress
      );

      search(`/api/v2/projects/search/?page_size=${projectsPerPage}`, params)
        .then((res: SearchResults) => {
          setCurrentPageResults(res);
        })
        .catch((err: unknown) => {})
        .finally(() => {
          setMyOrganisationsProjectsSearchStatus(
            MyOrganisationsProjectsSearchStatus.Inactive
          );
        });
      setSearchParams(params);
    },
    [myOrganisationsProjectsSearchStatus, search]
  );

  const organisation = useMyOrganisation();
  const searchWithFilter = useCallback(
    (filter: MyOrganisationFilterValue) => {
      if (organisation) {
        const params = myOrganisationsFilterToParams(filter, organisation);
        searchWithParams(params);
      }
    },
    [organisation, searchWithParams]
  );

  // Used for pagination, uses a cached version of the search params so that we
  // don't need to resubmit the project filter each time a page is changed:
  const searchWithPageNumber = (pageNumber: number) => {
    if (
      myOrganisationsProjectsSearchStatus ===
      MyOrganisationsProjectsSearchStatus.InProgress
    )
      return;
    setMyOrganisationsProjectsSearchStatus(
      MyOrganisationsProjectsSearchStatus.InProgress
    );
    search(
      `/api/v2/projects/search/?page=${pageNumber}&page_size=${projectsPerPage}`,
      searchParams
    )
      .then((res: SearchResults) => {
        setCurrentPageResults(res);
      })
      .catch((err: unknown) => {})
      .finally(() =>
        setMyOrganisationsProjectsSearchStatus(
          MyOrganisationsProjectsSearchStatus.Inactive
        )
      );
  };

  const value = {
    currentPageResults,
    searchParams,
    myOrganisationsProjectsSearchStatus,
    searchWithFilter,
    searchWithPageNumber,
    reset,
  };

  return (
    <MyOrganisationsProjectsContext.Provider value={value}>
      {children}
    </MyOrganisationsProjectsContext.Provider>
  );
};

export const MyOrganisationsProjectsContext =
  createContext<MyOrganisationsProjectsContextInterface>(null!);

export const useMyOrganisationsProjects = () => {
  return useContext(MyOrganisationsProjectsContext);
};
