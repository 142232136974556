import React from "react";
import { Modal } from "react-bootstrap";
import { LinkButton } from "components/button/LinkButton";

interface PromptModalProps {
  title: string;
  alignTitle?: "start" | "center" | "end";
  size?: "lg" | "sm" | "xl";
  content: React.ReactNode;
  show: boolean;
  cancelText?: string;
  onCancel?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const PromptModal = ({
  title,
  alignTitle = "start",
  size = "lg",
  content,
  show,
  cancelText,
  onCancel,
  children,
}: PromptModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onCancel}
      size={size}
      centered
      scrollable
      contentClassName="p-4"
    >
      <Modal.Header closeButton={!!onCancel}>
        <Modal.Title className={`w-100 text-${alignTitle}`}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="prompt-modal-body">{content}</Modal.Body>
      <Modal.Footer className="justify-content-center gap-2">
        {onCancel && (
          <LinkButton onClick={onCancel}>{cancelText || "Cancel"}</LinkButton>
        )}
        {children}
      </Modal.Footer>
    </Modal>
  );
};

export const ConfirmCancelPromptModal = ({
  confirmText,
  onConfirm,
  ...props
}: PromptModalProps & {
  confirmText?: string;
  onConfirm: () => void;
}) => (
  <PromptModal {...props}>
    <button type="button" className="btn btn-primary" onClick={onConfirm}>
      {confirmText || "Confirm"}
    </button>
  </PromptModal>
);
