import {
  useState,
  useMemo,
  useCallback,
  createContext,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useDjangoQuery } from "components/fetch/useDjangoQuery";
import { useDjangoApi } from "components/fetch/useDjangoApi";
import { useErrorModal } from "components/modal/useErrorModal";
import {
  useSavedSearches,
  SavedSearch,
} from "components/project/searched-projects/SavedSearchContext";
import {
  ConfirmCancelPromptModal,
  PromptModal,
} from "components/modal/PromptModal";

const watchlistEndpoint = "/api/v2/watchlists/";
const bookmarkEndpoint = watchlistEndpoint + "bookmark-project/";
const bookmarksEndpoint = watchlistEndpoint + "bookmarks/";

export const projectsPerWatchlist = 1000;

const bookmarkManyEndpoint = watchlistEndpoint + "bookmark-projects/";
const unbookmarkManyEndpoint = watchlistEndpoint + "unbookmark-projects/";

export const bookmarksName = "Bookmarked Projects";
export const bookmarksId = "bookmarks";
export const emptyBookmarks: WatchlistSummary = {
  id: NaN,
  name: bookmarksName,
  projects: 0,
  notifications: false,
};

export interface WatchlistProject {
  id: number;
  title: string;
  reference: string;
  organisation: string;
  start_date: string;
  end_date: string;
  clashes: number;
  opportunities: number;
  date_added: string;
}

export interface WatchlistSummary {
  id: number;
  name: string;
  projects: number;
  notifications: boolean;
}

export interface Watchlist {
  id: number;
  name: string;
  projects: WatchlistProject[];
}

export type WatchlistId = number | "bookmarks";

interface WatchlistContextInterface {
  isLoading: boolean;
  watchlists: WatchlistSummary[];
  bookmarks: WatchlistSummary;
  savedSearchWatchlists: WatchlistSummary[];
  addBookmark: (projectId: number) => Promise<void>;
  removeBookmark: (projectId: number, prompt?: boolean) => Promise<void>;
  addBookmarks: (projectIds: number[]) => Promise<void>;
  removeBookmarks: (projectIds: number[]) => Promise<void>;
  deleteWatchlist: (
    watchlistId: number,
    prompt?: boolean,
    doDeleteSearch?: boolean
  ) => Promise<void>;
  enableNotifications: (watchlistId: WatchlistId) => Promise<void>;
  disableNotifications: (watchlistId: WatchlistId) => Promise<void>;
  refetchWatchlists: () => void;
  invalidateWatchlistQuery: (watchlistId: WatchlistId) => Promise<void>;
  invalidateWatchlistQueries: () => Promise<any[]>;
}

export const WatchlistProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const api = useDjangoApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setErrorModal } = useErrorModal();
  const { savedSearches, deleteSearch, refreshSearches } = useSavedSearches();
  const {
    data,
    refetch: refetchWatchlists,
    isFetching: isLoadingWatchlists,
  } = useDjangoQuery<WatchlistSummary[]>(watchlistEndpoint);
  const [watchlists, setWatchlists] = useState<WatchlistSummary[]>([
    emptyBookmarks,
  ]);
  const [toast, setToast] = useState("");

  // project ID of project to remove from bookmarks
  const [projectIdToRemove, setProjectIdToRemove] = useState(-1);

  // watchlist ID to delete
  const [watchlistIdToDelete, setWatchlistIdToDelete] = useState(-1);

  const bookmarksFinder = (w: WatchlistSummary) => w.name === bookmarksName;

  useEffect(() => {
    if (!data || data.length === 0) {
      setWatchlists([emptyBookmarks]);
    } else if (!data.find(bookmarksFinder)) {
      setWatchlists([emptyBookmarks, ...data]);
    } else {
      setWatchlists(data);
    }
  }, [data]);

  const getWatchlist = useCallback(
    (watchlistId: WatchlistId) =>
      watchlists.find(
        watchlistId === bookmarksId
          ? bookmarksFinder
          : (w: WatchlistSummary) => w.id === watchlistId
      ),
    [watchlists]
  );

  const bookmarks = useMemo(
    () => getWatchlist(bookmarksId) ?? emptyBookmarks,
    [getWatchlist]
  );
  const savedSearchWatchlists = useMemo(
    () => watchlists.filter((w: WatchlistSummary) => w.name !== bookmarksName),
    [watchlists]
  );

  const { isFetching: isLoadingBookmarks, refetch: refetchBookmarks } =
    useWatchlist(bookmarks);

  const isLoading = isLoadingWatchlists || isLoadingBookmarks;

  const setWatchlistKey = useCallback(
    (watchlistId: WatchlistId, key: keyof WatchlistSummary, value: any) => {
      const summary = getWatchlist(watchlistId);
      if (!summary) return;

      const index = watchlists.indexOf(summary);
      const newWatchlists = [...watchlists];
      newWatchlists[index] = { ...watchlists[index], [key]: value };

      setWatchlists(newWatchlists);
    },
    [watchlists, getWatchlist]
  );

  const invalidateWatchlistQuery = useCallback(
    (watchlistId: WatchlistId) =>
      queryClient.invalidateQueries({
        queryKey: [
          "django",
          `${watchlistEndpoint}${watchlistId}/?page_size=${projectsPerWatchlist}`,
        ],
      }),
    [queryClient]
  );

  const invalidateWatchlistQueries = useCallback(
    () =>
      Promise.all([
        refetchWatchlists(),
        invalidateWatchlistQuery(bookmarksId),
        ...savedSearchWatchlists.map((watchlist: WatchlistSummary) =>
          invalidateWatchlistQuery(watchlist.id)
        ),
      ]),
    [savedSearchWatchlists, invalidateWatchlistQuery, refetchWatchlists]
  );

  const addBookmark = useCallback(
    async (projectId: number) => {
      try {
        await api.postDjangoApi(`${bookmarkEndpoint}${projectId}/`);
      } catch (error) {
        setToast("Could not add to Bookmarked Projects");
        throw error;
      }
      setToast("Added to Bookmarked Projects");
      await queryClient.invalidateQueries({
        queryKey: ["django", `/api/projects/${projectId}/`],
      });
      // don't wait for refetch
      refetchWatchlists();
      refetchBookmarks();
      return Promise.resolve();
    },
    [api, queryClient, refetchWatchlists, refetchBookmarks]
  );

  const removeBookmark = useCallback(
    async (projectId: number, prompt?: boolean) => {
      if (prompt) {
        setProjectIdToRemove(projectId);
        return;
      }
      try {
        await api.deleteDjangoApi(`${bookmarkEndpoint}${projectId}/`);
      } catch (error) {
        setToast("Could not remove from Bookmarked Projects");
        throw error;
      }
      setToast("Removed from Bookmarked Projects");
      await queryClient.invalidateQueries({
        queryKey: ["django", `/api/projects/${projectId}/`],
      });
      // don't wait for refetch
      refetchWatchlists();
      refetchBookmarks();
      setProjectIdToRemove(-1);
      return Promise.resolve();
    },
    [api, queryClient, refetchWatchlists, refetchBookmarks]
  );

  const addBookmarks = useCallback(
    async (projectIds: number[]) => {
      try {
        await api.postDjangoApi(bookmarkManyEndpoint, { projects: projectIds });
      } catch (error) {
        throw error;
      }
      for (const projectId of projectIds) {
        await queryClient.invalidateQueries({
          queryKey: ["django", `/api/projects/${projectId}/`],
        });
      }
      // don't wait for refetch
      refetchWatchlists();
      refetchBookmarks();
      return Promise.resolve();
    },
    [api, queryClient, refetchWatchlists, refetchBookmarks]
  );

  const removeBookmarks = useCallback(
    async (projectIds: number[]) => {
      try {
        await api.postDjangoApi(unbookmarkManyEndpoint, {
          projects: projectIds,
        });
      } catch (error) {
        throw error;
      }
      for (const projectId of projectIds) {
        await queryClient.invalidateQueries({
          queryKey: ["django", `/api/projects/${projectId}/`],
        });
      }
      // don't wait for refetch
      refetchWatchlists();
      refetchBookmarks();
      return Promise.resolve();
    },
    [api, queryClient, refetchWatchlists, refetchBookmarks]
  );

  const deleteWatchlist = useCallback(
    async (watchlistId: number, prompt?: boolean, doDeleteSearch?: boolean) => {
      if (prompt) {
        setWatchlistIdToDelete(watchlistId);
        return Promise.resolve();
      }

      const watchlist = watchlists.find(
        (w: WatchlistSummary) => w.id === Number(watchlistId)
      );
      if (!watchlist) {
        setErrorModal({
          title: "Watchlist not found",
          content: "Could not delete watchlist as it no longer exists.",
        });
        return Promise.resolve();
      }

      const search = savedSearches.find(
        (s: SavedSearch) => s.name === watchlist.name
      );
      try {
        await api.deleteDjangoApi(`${watchlistEndpoint}${watchlistId}/`);
        navigate("/dashboard");
        if (doDeleteSearch && search) {
          await deleteSearch(search);
          await refreshSearches();
        }
      } catch (error: unknown) {
        setErrorModal({
          title: "Watchlist deletion failed",
          content: "Failed to delete watchlist.",
        });
        throw error;
      }
      await refetchWatchlists();
      setToast("Deleted watchlist");
      setWatchlistIdToDelete(-1);
      return Promise.resolve();
    },
    [
      api,
      watchlists,
      savedSearches,
      deleteSearch,
      navigate,
      refetchWatchlists,
      refreshSearches,
      setErrorModal,
    ]
  );

  const enableNotifications = useCallback(
    async (watchlistId: WatchlistId) => {
      try {
        await api.postDjangoApi(
          `${watchlistEndpoint}${watchlistId}/enable-notifications/`
        );
      } catch (error: unknown) {
        setToast("Failed to enable notifications for this watchlist");
        throw error;
      }
      setToast("Notifications turned on.");
      setWatchlistKey(watchlistId, "notifications", true);
      return Promise.resolve();
    },
    [api, setWatchlistKey]
  );

  const disableNotifications = useCallback(
    async (watchlistId: WatchlistId) => {
      try {
        await api.postDjangoApi(
          `${watchlistEndpoint}${watchlistId}/disable-notifications/`
        );
      } catch (error: unknown) {
        setToast("Failed to disable notifications for this watchlist");
        throw error;
      }
      setToast("Notifications turned off.");
      setWatchlistKey(watchlistId, "notifications", false);
      return Promise.resolve();
    },
    [api, setWatchlistKey]
  );

  const value = {
    isLoading,
    watchlists,
    bookmarks,
    savedSearchWatchlists,
    addBookmark,
    removeBookmark,
    addBookmarks,
    removeBookmarks,
    deleteWatchlist,
    enableNotifications,
    disableNotifications,
    refetchWatchlists,
    invalidateWatchlistQuery,
    invalidateWatchlistQueries,
  };

  return (
    <WatchlistContext.Provider value={value}>
      {children}
      <ToastContainer
        className="p-3"
        position="bottom-center"
        style={{ zIndex: 1000 }}
      >
        <Toast
          onClose={() => setToast("")}
          show={!!toast}
          delay={1500}
          autohide
        >
          <Toast.Body className="d-flex justify-content-center p-4">
            {toast}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ConfirmCancelPromptModal
        show={!isLoading && projectIdToRemove > 0}
        title="Are you sure?"
        content="This will remove the project from your watchlist."
        onConfirm={() => removeBookmark(projectIdToRemove)}
        onCancel={() => setProjectIdToRemove(-1)}
      />
      <PromptModal
        show={!isLoading && watchlistIdToDelete > 0}
        title="Delete watchlist"
        content="This watchlist is created from a saved search. Do you want to delete the saved search too?"
        onCancel={() => setWatchlistIdToDelete(-1)}
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => deleteWatchlist(watchlistIdToDelete, false, true)}
        >
          Delete saved search and watchlist
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => deleteWatchlist(watchlistIdToDelete, false, false)}
        >
          Delete watchlist only
        </button>
      </PromptModal>
    </WatchlistContext.Provider>
  );
};

export const WatchlistContext = createContext<WatchlistContextInterface>(null!);

export const useWatchlists = () => useContext(WatchlistContext);

export const useWatchlist = (watchlist: WatchlistSummary) =>
  useDjangoQuery<Watchlist>(
    watchlist.name === bookmarksName
      ? // limit projects on any watchlist detail view
        `${bookmarksEndpoint}?page_size=${projectsPerWatchlist}`
      : `${watchlistEndpoint}${watchlist.id}/?page_size=${projectsPerWatchlist}`
  );
