import { FormFieldConfig } from "utils/types/form";
import { AddressField } from "components/form/AddressField";
import { LatLngBounds } from "leaflet";

const fieldName = "location";

export const locationConfig: FormFieldConfig = {
  name: fieldName,
  initialValue: "",
  validationSchema: undefined,
};

export const Location = ({
  label = "",
  showDelete = false,
  onSelectCompletion = (wkt) => {},
}: {
  label?: string;
  showDelete?: boolean;
  onSelectCompletion: (wkt: LatLngBounds) => void;
}) => {
  return (
    <div className="w-100">
      <AddressField
        label={label}
        name={fieldName}
        placeholder="Find address or place"
        showDelete={showDelete}
        srid="4326"
        showLabel={false}
        onSelectCompletion={onSelectCompletion}
      />
    </div>
  );
};
