import React, { useState, useCallback, useEffect } from "react";
import { useField, FieldHookConfig } from "formik";
import { FieldWrapper } from "./FieldWrapper";
import { Label } from "./Label";
import { GeoJSON, LatLngBounds } from "leaflet";
import {
  Completion,
  Feature,
  AddressSuggestionList,
} from "./AddressSuggestionsList";
import { FieldError } from "./FieldError";
import { ReactComponent as DeleteIcon } from "assets/control-icons/xmark-solid.svg";
import axios from "axios";
import { stringify, parse } from "wkt";
import "./AddressField.scss";

interface Props {
  label: string;
  showDelete?: boolean;
  srid: string;
  showLabel?: boolean;
  layers?: Array<string>;
  clearCondition?: boolean;
  onSelectCompletion?: (wkt: LatLngBounds) => void;
}

interface AddressValue {
  input: string;
  wkt: string;
}

const searchAddress = async (searchTerm: string) => {
  searchTerm = searchTerm.trim().toLowerCase();

  const params = new URLSearchParams();
  params.append("query", searchTerm);

  const completions = await axios.get("/api/v3/location/completions/", {
    params,
  });
  return completions?.data ?? [];
};

export const AddressField = (
  props: Props &
    FieldHookConfig<AddressValue> &
    React.HTMLProps<HTMLInputElement>
) => {
  const { label, showLabel, showDelete, srid, clearCondition, onSelectCompletion, ...rest } = props;
  const [field, meta, helpers] = useField(rest);
  const {
    value: { input, wkt },
  } = field;
  const { setValue, setError, setTouched } = helpers;
  const isError = meta.touched && meta.error;
  const id = props.id || props.name;
  const [requestingFeature, setRequestingFeature] = useState(false);
  const [completions, setCompletions] = useState<Array<Completion>>([]);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [enableRequests, setEnableRequests] = useState(true);

  const reset = useCallback(() => {
    if (input || wkt) {
      setValue({ input: "", wkt: "" });
    }
  }, [input, wkt, setValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnableRequests(true);
    setValue({ input: e.target.value, wkt: "" }); // This is to stop users changing the search string but not the actual location.
  };

  const onFocus = () => {
    setIsFocused(true);
    setTouched(true);
  };

  const onBlur = () => {
    setIsFocused(false);
    if (input !== "" && !wkt) {
      setError("No address selected. Input will be ignored.");
    }
  };

  const onSelect = async (completion: Completion) => {
    setValue({ input: completion.completion, wkt });

    if (!requestingFeature) {
      setRequestingFeature(true);

      const resp = await axios.get(completion.href);
      const feature: Feature = resp?.data?.features[0];

      // Convert GeoJSON object to wkt
      const wkt = `SRID=${srid};${stringify(feature.geometry)}`

      // Set the value on the field
      setValue({
        input: completion.completion,
        wkt: wkt,
      });
      setEnableRequests(false);
      setHideSuggestions(true);

      setRequestingFeature(false);
      if (onSelectCompletion) {
        const geojson = new GeoJSON(parse(wkt));
        onSelectCompletion(geojson.getBounds());
      }
    }
  };

  useEffect(() => {
    if (input && enableRequests) {
      setCompletions([]);
      setHideSuggestions(true);

      const delay = setTimeout(() => {
        searchAddress(input).then((results) => {
          if (results) {
            setCompletions(results);
            setHideSuggestions(false);
          }
        });
      }, 500);

      return () => {
        clearTimeout(delay);
      };
    }
  }, [enableRequests, input]);

  useEffect(() => {
    clearCondition && reset();
  }, [clearCondition, reset]);

  return (
    <div className="d-flex flex-row align-items-center w-100">
      <FieldWrapper noWrapper={showDelete}>
        {showLabel && (
          <Label
            htmlFor={id}
            label={label}
            required={props.required} // required is used only cosmetically for label
          />
        )}
        <div className="position-relative location-input">
          <input
            {...rest}
            {...field}
            id={id}
            className="form-control"
            type={props.type || "text"}
            required={false} // Don't want browser default validation
            autoComplete="off"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={input ?? ""}
          />
          <AddressSuggestionList
            completions={completions}
            isHidden={hideSuggestions || !input || !isFocused}
            onSelect={onSelect}
          />
          {showDelete && input && (
            <i className="clear-icon">
              <DeleteIcon
                width={24}
                height={24}
                className="btn-delete"
                onClick={reset}
              />
            </i>
          )}
        </div>
        {!requestingFeature && isError && <FieldError>{meta.error}</FieldError>}
      </FieldWrapper>
    </div>
  );
};
