import { createContext, useState, useContext, useEffect } from "react";
import type { Feature } from "geojson";

interface SelectedContextualFeatureInterface {
  selectedContextualFeature: Feature | null;
  setSelectedContextualFeature: (feature: Feature | null) => void;
  layerKey: string;
  setLayerKey: React.Dispatch<React.SetStateAction<string>>;
  layerName: string;
  setLayerName: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectedContextualFeatureContext =
  createContext<SelectedContextualFeatureInterface>(null!);

export const SelectedContextualFeatureProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [syncedSelectedContextualFeature, setSyncedSelectedContextualFeature] =
    useState<Feature | null>(null);
  const [selectedContextualFeature, setSelectedContextualFeature] =
    useState<Feature | null>(null);
  const [layerKey, setLayerKey] = useState("");
  const [layerName, setLayerName] = useState("");

  /*
  Becuase of React batching with leaflet map updates
  we can't update the selected feature directly.
  Setting it to null then using another State to update
  the selected feature through a useEffect will cause leaflet
  to update the map. 
  */

  function changeSelectedContextualFeature(feature: Feature | null): void {
    setSyncedSelectedContextualFeature(null);
    setSelectedContextualFeature(feature);
  }

  useEffect(() => {
    setSyncedSelectedContextualFeature(selectedContextualFeature);
  }, [selectedContextualFeature]);

  const value = {
    selectedContextualFeature: syncedSelectedContextualFeature,
    setSelectedContextualFeature: changeSelectedContextualFeature,
    layerKey,
    setLayerKey,
    layerName,
    setLayerName,
  };
  return (
    <SelectedContextualFeatureContext.Provider value={value}>
      {children}
    </SelectedContextualFeatureContext.Provider>
  );
};

export const useSelectedContextualFeature = () => {
  return useContext(SelectedContextualFeatureContext);
};
