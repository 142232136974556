import { Formik, Form } from "formik";
import { buildFormConfig } from "utils/form";
import { CancelButton } from "./CancelButton";
import { ProjectName } from "./formFields/ProjectName";
import { Reference } from "./formFields/Reference";
import { EditProjectDuration } from "./formFields/EditProjectDuration";
import { ContactName } from "./formFields/ContactName";
import { ContactPhone } from "./formFields/ContactPhone";
import { ContactEmail } from "./formFields/ContactEmail";
import { ProjectType } from "./formFields/ProjectType";
import { InfrastructureType } from "./formFields/InfrastructureType";
import { ProjectDetails } from "./formFields/ProjectDetails";
import { DeleteProjectButton } from "./DeleteProjectButton";

export const EditProjectSummaryForm = ({
  pageValues,
  isMultiStage,
  onSubmit,
  onNext,
  project,
  forceNavigate,
}: {
  pageValues: any;
  isMultiStage: boolean;
  onSubmit: (values: any) => Promise<void>;
  onNext: (values: any) => void;
  project: any;
  forceNavigate: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { validationSchema } = buildFormConfig(
    ProjectName.config,
    Reference.config,
    EditProjectDuration.config,
    ContactName.config,
    ContactPhone.config,
    ContactEmail.config,
    ProjectType.config,
    InfrastructureType.config,
    ProjectDetails.config
  );

  return (
    <>
      <h3 className="h5">Project summary</h3>
      <Formik
        initialValues={pageValues}
        validationSchema={validationSchema}
        // There are 2 main paths. No default submit function so that enter key doesn't invoke either.
        onSubmit={() => {}}
      >
        {({ values }) => (
          <Form noValidate>
            <ProjectName />
            <Reference />
            <EditProjectDuration />
            <ContactName />
            <ContactPhone />
            <ContactEmail />
            <ProjectType />
            <InfrastructureType projectType={values.projectType} />
            <ProjectDetails />

            <div className="d-flex align-items-baseline justify-content-between gap-3">
              <DeleteProjectButton
                projectId={project.id}
                projectTitle={project.title}
                forceNavigate={forceNavigate}
              />
              <div className="d-flex align-items-baseline justify-content-end gap-3">
                <CancelButton />
                {isMultiStage ? (
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={() => onSubmit(values)}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={() => onNext(values)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {!isMultiStage && (
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => onSubmit(values)}
                >
                  Save and close
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
