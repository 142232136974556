import { useDjangoQuery } from "./useDjangoQuery";
import { PaginatedResults, Project } from "utils/types/django";
import { Id } from "components/project/SelectedProjectContext";
import { useMyOrganisation } from "./useMyOrganisation";
import { Organisation } from "utils/types/django";

export const useProjectsQuery = (
  params?: FormData,
  enabled: boolean = true
) => {
  return useDjangoQuery<PaginatedResults<Project>>(
    params ? `/api/projects/?${params.toString()}` : "/api/projects/",
    {
      enabled,
    }
  );
};

export const myOrganisationsFilterToParams = (
  filter?: Record<string, any>,
  organisation?: Organisation
): FormData => {
  // TODO: Filter by published
  const params = new FormData();
  if (organisation?.name) {
    params.append("organisation", organisation.name);
  }
  params.append("state", "PLANNED");
  params.append("state", "IN_PROGRESS");
  params.append("state", "DEFERRED");
  params.append("state", "STALLED");
  params.append("order_by", "-last_updated");

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      // MultipleCombobox values are stored in an array.
      // Append them separately to the params.
      if (Array.isArray(value)) {
        value.forEach((item: any) => {
          params.append(key, item ?? "");
        });
      } else {
        params.append(key, value);
      }
    });
  }

  return params;
};

export const useMyOrganisationProjectsQuery = (
  filter?: Record<string, any>
) => {
  const organisation = useMyOrganisation();
  const params = myOrganisationsFilterToParams(filter, organisation);
  return useProjectsQuery(params, !!organisation);
};

export const useProjectByIdQuery = (id: Id | string) => {
  return useDjangoQuery<Project>(`/api/projects/${id}/`, {
    enabled: !!id,
  });
};
