import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constants/paths";
import { useMatchPath } from "utils/path";
import { useSelectedProject } from "components/project/useSelectedProject";
import { ProjectSummaryTable } from "./ProjectSummaryTable";
import { Tabs, TabItem, TabContent, TabPane } from "components/tab";
import { StageDetails } from "./StageDetails";
import { useProjectByIdQuery } from "components/fetch/useProjectsQuery";
import { EditProjectButton } from "./EditProjectButton";
import { MakeProjectMultiStageButton } from "./MakeProjectMultiStageButton";
import { ClashDetails } from "./ClashDetails";
import { ClashButton } from "./ClashButton";
import { OpportunitiesDetails } from "./OpportunitiesDetails";
import { OpportunitiesButton } from "./OpportunitiesButton";
import {
  ClashOpportunityState,
  useClashOpportunityState,
} from "./ClashOpportunityState";
import { PanelWrapper } from "../PanelWrapper";
import { ClopPanel } from "./ClopPanel";
import {
  usePanels,
  PanelID,
  PanelVisibility,
} from "components/panel/PanelsProvider";
import "./ProjectPanel.scss";
import { BookmarkProjectButton } from "./BookmarkProjectButton";
import { CollapsiblePanelBody } from "../CollapsiblePanelBody";

// These don't need to be defined on every render so put here
const summaryId = "project-summary";
const stagesId = "project-stages";
const panelId = PanelID.Project;

export const ProjectPanel = () => {
  const navigate = useNavigate();
  // Check that this is specifically the root project path, not projects
  // (plural), or a subpath of project. The "Stages" tab is part of the project
  // panel so we should include it's path in the check.
  const isOnStagesPath = useMatchPath(Path.Stages);
  const isOnStageByIdPath = useMatchPath(Path.StageById);
  const isOnProjectByIdPath = useMatchPath(Path.ProjectById);

  const {
    panels,
    setPanelVisibilities,
    isExpanded,
    isCollapsed,
    isClosed,
    closePanel,
    isOpen,
    someSearchPanelsAreOpen,
  } = usePanels();

  const [clopState, setClopState] = useClashOpportunityState();
  const {
    selectedProject,
    selectedClop,
    isStageTabActive,
    selectClop,
    navigateToSummaryTab,
    navigateToStagesTab,
  } = useSelectedProject();
  const { data: project } = useProjectByIdQuery(selectedProject);

  // reset displayed clashes/opps when a user selects another project
  useEffect(() => {
    setClopState(ClashOpportunityState.None);
  }, [selectClop, selectedProject, setClopState]);

  useEffect(() => {
    /**
     * When navigating to the project path expand the project panel (except when
     * returning from an edit project panel - that will be explicitly set from
     * those panels), collapse the search panels if they're open and the project
     * was not already open.

     * We should also close any edit project panels (Edit Project, Add Stage,
     * and Edit Stage panels if they have been left open, but keep the Search
     * Results panel from collapsing in those cases. This is because edit
     * project panels could be considered part of the Project panel from the
     * user's perspective (even though they are distinct in code) and shouldn't
     * be kept open simultaneously.
     */
    if (
      isClosed(panelId) &&
      (isOnStagesPath || isOnStageByIdPath || isOnProjectByIdPath)
    ) {
      const searchFormVisibility = isOpen(PanelID.SearchForm)
        ? PanelVisibility.Collapsed
        : PanelVisibility.Closed;
      const searchResultsVisibility =
        isOpen(PanelID.SearchForm) &&
        isOpen(PanelID.SearchResults) &&
        isClosed(PanelID.SearchResultsInArea)
          ? PanelVisibility.Collapsed
          : PanelVisibility.Closed;
      const searchResultsInAreaVisibility = isOpen(PanelID.SearchResultsInArea)
        ? PanelVisibility.Collapsed
        : PanelVisibility.Closed;
      const myOrganisationVisibility = isOpen(PanelID.MyOrganisation)
        ? PanelVisibility.Collapsed
        : PanelVisibility.Closed;
      setPanelVisibilities([
        { id: PanelID.SearchForm, visibility: searchFormVisibility },
        { id: PanelID.SearchResults, visibility: searchResultsVisibility },
        {
          id: PanelID.SearchResultsInArea,
          visibility: searchResultsInAreaVisibility,
        },
        { id: PanelID.Projects, visibility: PanelVisibility.Closed },
        { id: PanelID.Project, visibility: PanelVisibility.Expanded },
        { id: PanelID.EditProject, visibility: PanelVisibility.Closed },
        { id: PanelID.AddStage, visibility: PanelVisibility.Closed },
        { id: PanelID.EditStage, visibility: PanelVisibility.Closed },
        { id: PanelID.MyOrganisation, visibility: myOrganisationVisibility },
      ]);
    }
  }, [
    isExpanded,
    setPanelVisibilities,
    isOnStagesPath,
    isOnStageByIdPath,
    isOnProjectByIdPath,
    isCollapsed,
    isClosed,
    isOpen,
    someSearchPanelsAreOpen,
  ]);

  useEffect(() => {
    // When no longer on project path, ensure that panel is closed:
    if (
      isExpanded(panelId) &&
      !(isOnStagesPath || isOnStageByIdPath || isOnProjectByIdPath) &&
      !(isOnStagesPath || isOnStageByIdPath)
    ) {
      closePanel(panelId);
    }
  }, [
    closePanel,
    isExpanded,
    isOnProjectByIdPath,
    isOnStagesPath,
    isOnStageByIdPath,
  ]);

  // Project id validation is done in ProjectValidator.tsx.
  // Here we just need to wait for fetch.
  if (!project) {
    return null;
  }

  return (
    <div className="d-flex">
      <PanelWrapper isOpen={isOpen(panelId)}>
        <CollapsiblePanelBody
          panelId={panelId}
          expandedTitle={project.title}
          isCollapsible={false}
          hideCloseButton={isCollapsed(panelId) || !!selectedClop}
          onClose={() => {
            closePanel(panelId);

            if (isOpen(PanelID.MyOrganisation)) {
              navigate(Path.MyOrganisation);
            } else if (someSearchPanelsAreOpen) {
              navigate(Path.Search);
            } else if (panels.length === 1) {
              navigate(Path.Index);
            } else {
              navigate(-1);
            }
          }}
        >
          <div className="d-flex gap-2">
            <div className="flex-fill">
              <Tabs>
                <TabItem
                  paneId={summaryId}
                  active={!isStageTabActive}
                  onClick={navigateToSummaryTab}
                >
                  Summary
                </TabItem>
                {project.is_multistage && (
                  <TabItem
                    paneId={stagesId}
                    active={isStageTabActive}
                    onClick={navigateToStagesTab}
                  >
                    Stages ({project.stages.length})
                  </TabItem>
                )}
              </Tabs>
            </div>
            <BookmarkProjectButton />
          </div>

          <TabContent>
            <TabPane id={summaryId} active={!isStageTabActive}>
              <div className="d-flex justify-content-between align-items-center project-title mb-2">
                <h3 className="h5 mb-0">Project summary</h3>
                <EditProjectButton organisationId={project.organisation.id} />
              </div>

              <ProjectSummaryTable project={project} />
              <div className="d-flex justify-content-between mt-1">
                <div className="d-flex gap-2">
                  <ClashButton
                    active={
                      !project.is_multistage &&
                      clopState === ClashOpportunityState.Clashes
                    }
                    disabled={project.is_multistage}
                    onClick={() => {
                      setClopState((state) =>
                        state === ClashOpportunityState.Clashes
                          ? ClashOpportunityState.None
                          : ClashOpportunityState.Clashes
                      );
                    }}
                  />
                  <OpportunitiesButton
                    active={
                      !project.is_multistage &&
                      clopState === ClashOpportunityState.Opportunities
                    }
                    disabled={project.is_multistage}
                    onClick={() => {
                      setClopState((state) =>
                        state === ClashOpportunityState.Opportunities
                          ? ClashOpportunityState.None
                          : ClashOpportunityState.Opportunities
                      );
                    }}
                  />
                </div>
              </div>
              {clopState === ClashOpportunityState.Clashes &&
                !project.is_multistage && (
                  <ClashDetails stages={project?.stages} />
                )}
              {clopState === ClashOpportunityState.Opportunities &&
                !project.is_multistage && (
                  <OpportunitiesDetails stages={project?.stages} />
                )}
              {!project.is_multistage && (
                <>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <MakeProjectMultiStageButton />
                  </div>
                </>
              )}
            </TabPane>

            {project.is_multistage && (
              <TabPane id={stagesId} active={isStageTabActive}>
                <StageDetails
                  organisationId={project.organisation.id}
                  stages={project.stages}
                />
              </TabPane>
            )}
          </TabContent>
        </CollapsiblePanelBody>
      </PanelWrapper>

      <PanelWrapper isOpen={!!selectedClop}>
        <ClopPanel />
      </PanelWrapper>
    </div>
  );
};
