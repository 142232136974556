import { useState, useEffect, useCallback } from "react";
import "./AddressSuggestionsList.scss";

interface FeatureGeometry {
  type: string;
  coordinates: [];
}

export interface Completion {
  feat_id: string;
  completion: string;
  dataset: string;
  score: number;
  href: string;
}

interface FeatureProperties {
  full_address_number: string;
  full_road_name: string;
  full_address: string;
  suburb_locality: string;
  town_city: string;
}

export interface Feature {
  geometry: FeatureGeometry;
  properties: FeatureProperties;
}

interface AddressSuggestionListItemProps {
  completion: Completion;
  isActive: boolean;
  onMouseEnter: () => void;
  onMouseDown: () => void;
}

interface AddressSuggestionListProps {
  completions: Array<Completion>;
  isHidden: boolean;
  onSelect: (completion: Completion) => void;
}

const restrictRange = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  } else if (value >= max) {
    return max - 1;
  }

  return value;
};

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    document.addEventListener("keydown", downHandler);
    document.addEventListener("keyup", upHandler);

    return () => {
      document.removeEventListener("keydown", downHandler);
      document.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export const AddressSuggestionListItem = ({
  completion,
  isActive,
  onMouseEnter,
  onMouseDown,
}: AddressSuggestionListItemProps) => {
  const title = `${completion.completion}`;

  return (
    <li
      className={"p-1 list-group-item" + (isActive ? " active" : "")}
      onMouseEnter={onMouseEnter}
      onMouseDown={onMouseDown}
    >
      {title}
    </li>
  );
};

export const AddressSuggestionList = ({
  completions,
  isHidden,
  onSelect,
}: AddressSuggestionListProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowUpPressed = useKeyPress("ArrowUp");
  const enterPressed = useKeyPress("Enter");

  const selectActive = useCallback(() => {
    if (activeIndex >= 0 && activeIndex < completions.length) {
      onSelect(completions[activeIndex]);
      setActiveIndex(-1);
    }
  }, [activeIndex, completions, onSelect]);

  useEffect(() => {
    if (arrowUpPressed) {
      setActiveIndex((idx: number) =>
        completions ? restrictRange(idx - 1, 0, completions.length) : -1
      );
    }
  }, [completions, arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      setActiveIndex((idx: number) =>
        completions ? restrictRange(idx + 1, 0, completions.length) : -1
      );
    }
  }, [completions, arrowDownPressed]);

  useEffect(() => {
    if (enterPressed) {
      setTimeout(() => selectActive(), 1); // timeout used to prevent useeffect from causing loop, see: https://github.com/jaredpalmer/formik/issues/3602
    }
  }, [selectActive, enterPressed]);

  return isHidden ? null : (
    <ul className="list-group position-absolute overflow-y-scroll address-list">
      {completions.map((completion: Completion, idx: number) => (
        <AddressSuggestionListItem
          key={idx}
          completion={completion}
          isActive={activeIndex === idx}
          onMouseEnter={() => setActiveIndex(idx)}
          onMouseDown={() => selectActive()}
        />
      ))}
      {completions.length <= 0 && (
        <li className="list-group-item text-muted">
          No matching address found
        </li>
      )}
    </ul>
  );
};
