import {
  ContextualLayerGroup as GroupType,
  useLayers,
} from "components/layers/LayersProvider";
import { useParentCheckState } from "./useParentCheckState";
import {
  CascadingCheckboxAccordion,
  CHECKBOX_STATES,
} from "./CascadingCheckboxAccordion";
import { slugify } from "utils/slugify";
import { ESRIMapServerLegend } from "./ESRIMapServerLegend";
import { ESRIFeatureServerLegend } from "./ESRIFeatureServerLegend";

const ContextualLayerGroup = ({
  groupName,
  layerGroup,
}: {
  groupName: string;
  layerGroup: GroupType;
}) => {
  const { layers, checkStates, toggleCheckStates } = layerGroup;
  const checked = useParentCheckState(checkStates);

  return (
    <CascadingCheckboxAccordion
      id={`layer-${slugify(groupName)}`}
      title={groupName}
      indentation={false}
      checked={checked}
      toggleChecked={() => {
        // A whole contextual layer group can be very huge (e.g. Wellington)
        // so don't make it possible to turn them on all at once.
      }}
      disabled
    >
      {layers?.map((layer) => (
        <CascadingCheckboxAccordion
          key={layer.layerKey}
          title={layer.name}
          id={`layer-${layer.layerKey}`}
          checked={
            checkStates[layer.layerKey]
              ? CHECKBOX_STATES.Checked
              : CHECKBOX_STATES.Empty
          }
          toggleChecked={() => {
            toggleCheckStates(layer.layerKey);
          }}
        >
          {layer.base_url.includes("MapServer") && (
            <ESRIMapServerLegend layer={layer} />
          )}
          {layer.base_url.includes("FeatureServer") && (
            <ESRIFeatureServerLegend layer={layer} />
          )}
        </CascadingCheckboxAccordion>
      ))}
    </CascadingCheckboxAccordion>
  );
};

export const ContextualLayers = () => {
  const {
    contextualLayers: {
      national,
      auckland,
      wellington,
      queenstown,
      newPlymouth,
    },
  } = useLayers();

  return (
    <>
      <ContextualLayerGroup groupName="National" layerGroup={national} />
      <ContextualLayerGroup groupName="Auckland" layerGroup={auckland} />
      <ContextualLayerGroup groupName="New Plymouth" layerGroup={newPlymouth} />
      <ContextualLayerGroup groupName="Queenstown" layerGroup={queenstown} />
      <ContextualLayerGroup groupName="Wellington" layerGroup={wellington} />
    </>
  );
};
