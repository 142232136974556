import { Collapse } from "react-bootstrap";

export const PanelWrapper = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Collapse in={isOpen} dimension="width">
      <div className="panel-wrapper position-relative">{children}</div>
    </Collapse>
  );
};
