import { LinkButton } from "components/button/LinkButton";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useHoveredProject } from "./hovered-project/HoveredProjectContext";

export const ProjectLink = ({
  project,
  flyTo = false,
  text,
}: {
  project: {
    id: number;
    title: string;
  };
  flyTo?: boolean;
  text?: string;
}) => {
  const { navigateToProject } = useSelectedProject();
  const { setHoveredProjectId, removeHover } = useHoveredProject();

  return (
    <LinkButton
      type="button"
      onClick={() => navigateToProject(project.id, flyTo)}
      onMouseEnter={() => setHoveredProjectId(project.id)}
      onMouseLeave={removeHover}
    >
      {text ?? `#${project.id} ${project.title}`}
    </LinkButton>
  );
};
