import "esri-leaflet-renderers";

import { FeatureLayer } from "react-esri-leaflet";
import { ContextualLayerGroup as GroupType } from "components/layers/LayersProvider";
import { useSelectedContextualFeature } from "components/layers/SelectedContextualFeatureProvider";
import { useLayers } from "components/layers/LayersProvider";

const ContextualLayerGroup = ({ layerGroup }: { layerGroup: GroupType }) => {
  const {
    contextualLayers: { layerVisibility },
  } = useLayers();
  const { setSelectedContextualFeature, setLayerKey, setLayerName } =
    useSelectedContextualFeature();
  return (
    <>
      {layerGroup.layers?.map((layer) => {
        if (!layerVisibility[layer.layerKey]) return null;
        if (layer.service === "ESRI") {
          const where =
            layer.feature_filter?.filterFieldName &&
            layer.feature_filter?.typeId
              ? `${layer.feature_filter.filterFieldName}=${layer.feature_filter.typeId}`
              : "1=1";
          return (
            <FeatureLayer
              key={layer.layerKey}
              url={layer.base_url}
              where={where}
              // Canvas was blocking click on projects on vector tiles.
              // Less performant but svg is better for this use case.
              // renderer={new L.Canvas()}
              eventHandlers={{
                click: (e: any) => {
                  setLayerKey(layer.layerKey);
                  setLayerName(layerGroup.name + " " + layer.name);
                  setSelectedContextualFeature(e.layer?.feature);
                },
              }}
            />
          );
        }
        // Currently no contextual layers use other services (WFS, WMS)
        // So we don't implement them for now.
        return null;
      }) ?? null}
    </>
  );
};

export const ContextualLayer = () => {
  const {
    contextualLayers: {
      national,
      auckland,
      wellington,
      queenstown,
      newPlymouth,
    },
  } = useLayers();

  return (
    <>
      <ContextualLayerGroup layerGroup={national} />
      <ContextualLayerGroup layerGroup={auckland} />
      <ContextualLayerGroup layerGroup={wellington} />
      <ContextualLayerGroup layerGroup={queenstown} />
      <ContextualLayerGroup layerGroup={newPlymouth} />
    </>
  );
};
