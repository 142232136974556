import { useEffect, useRef } from "react";
import { VectorGrid } from "leaflet";
import { format, parse } from "date-fns";
import { useMap } from "react-leaflet";

import { LayerProperties } from "./VectorGrid";
import { DATE_FORMAT, BACKEND_DATE_FORMAT } from "utils/constants/date";
import { useLayers } from "components/layers/LayersProvider";

const formatDate = (date: string) => {
  try {
    // do nothing for the range error when the `date` is not in the expected shape.
    // we just return an empty string.
    return format(parse(date, BACKEND_DATE_FORMAT, new Date()), DATE_FORMAT);
  } catch {}
  return "";
};

/**
 * Shows project info in popup on hovering project polygons.
 */
export const useProjectPopup = (vectorGrid: VectorGrid.CustomProtobuf) => {
  const { showProjectPopup } = useLayers();

  const map = useMap();
  const popupId = useRef<string | null>(null);
  const cursorPosition = useRef<any>(null);
  useEffect(() => {
    if (!showProjectPopup) return;

    // no content by default
    const popup = L.popup({
      autoPan: false,
      offset: new L.Point(0, -2),
      closeButton: false,
    });
    vectorGrid.bindPopup(popup);

    const onMousemove = (e: any) => {
      // Popup shouldn't open when the map is too zoomed out.
      // It's heuristic and the number may change over time.
      // 14 is 500m level on the scale display.
      if (map.getZoom() < 14) return;

      const properties: LayerProperties = e.layer?.properties;
      if (!properties) return;
      const { project_title, org_name, start_date, end_date } = properties;
      popupId.current = project_title;
      cursorPosition.current = e.latlng;

      // debounce to prevent popup opening while moving the mouse cursor
      setTimeout(() => {
        // don't show the popup if the cursor moved out of the project
        if (popupId.current !== project_title) return;

        vectorGrid.setPopupContent(
          `<div><div><strong>${project_title}</strong></div><div>${org_name}</div><div>${formatDate(
            start_date
          )} - ${formatDate(end_date)}</div></div>`
        );
        vectorGrid.openPopup(cursorPosition.current);
      }, 300);
    };
    const onMouseout = (e: any) => {
      popupId.current = null;
      setTimeout(() => {
        // Don't close if quickly switched to the next polygon
        if (popupId.current === null) {
          vectorGrid.closePopup();
        }
      }, 200);
    };

    vectorGrid.addEventListener("mousemove", onMousemove);
    vectorGrid.addEventListener("mouseout", onMouseout);
    return () => {
      vectorGrid.removeEventListener("mousemove", onMousemove);
      vectorGrid.removeEventListener("mouseout", onMouseout);
      vectorGrid.unbindPopup();
    };
    // Intentionally no dependencies to avoid popup not being displayed
    // after user clicks a project. IDK how it exactly works but this works.
    // Probably something in vectorGrid (but not the vectorGrid itself) changes
    // and openPopup doesn't work unless unbinding and binding it.
  });
};
