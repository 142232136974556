import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  WatchlistSummary,
  bookmarksId,
  bookmarksName,
} from "components/watchlist/WatchlistContext";
import { WatchlistNotificationsButton } from "./WatchlistNotificationsButton";
import "./Watchlist.scss";

export interface WatchlistListItemProps {
  key?: string;
  watchlist: WatchlistSummary;
}

export const WatchlistListItem = ({ watchlist }: WatchlistListItemProps) => {
  const navigate = useNavigate();
  const { watchlistId } = useParams();
  const isBookmarks = watchlist.name === bookmarksName;
  const id = isBookmarks ? bookmarksId : watchlist.id;
  const active =
    (isBookmarks && watchlistId === bookmarksId) ||
    (!isBookmarks && Number(watchlistId) === watchlist.id);

  const nonZeroSubtitle =
    watchlist.projects === 1
      ? "1 Project Watchlisted"
      : `${watchlist.projects} Projects Watchlisted`;
  const subtitle = watchlist.projects > 0 ? nonZeroSubtitle : "Currently empty";

  return (
    <div
      className={classNames(
        "watchlist-list-item d-flex align-items-center list-group-item list-group-item-action p-2",
        { active }
      )}
      aria-current={active}
    >
      <button
        className="btn flex-grow-1"
        onClick={() => navigate(`/dashboard/watchlist/${id}`)}
      >
        <div className="d-flex flex-column">
          <strong>{watchlist.name}</strong>
          <small className="fw-300">{subtitle}</small>
        </div>
      </button>
      <WatchlistNotificationsButton watchlist={watchlist} />
    </div>
  );
};
