import { PaginationContainer } from "components/project/pagination/PaginationContainer";
import {
  PaginationNavigation,
  projectsPerPage,
} from "components/project/pagination/PaginationNavigation";
import {
  useMyOrganisationsProjects,
  MyOrganisationsProjectsSearchStatus,
} from "./MyOrganisationsProjectsContext";

interface PublishedProjectsTablePaginationProps {
  totalProjectsCount: number;
  currentPageNumber: number;
}

export function PublishedProjectsTablePagination({
  totalProjectsCount,
  currentPageNumber,
}: PublishedProjectsTablePaginationProps) {
  const { myOrganisationsProjectsSearchStatus } = useMyOrganisationsProjects();

  const pageCount = Math.ceil(totalProjectsCount / projectsPerPage);

  return (
    <PaginationContainer
      pageCount={pageCount}
      currentPageNumber={currentPageNumber}
    >
      {myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.Inactive && (
        <PaginationNavigation
          pageCount={pageCount}
          currentPageNumber={currentPageNumber}
          currentRouteSlug="my-organisation"
        />
      )}
    </PaginationContainer>
  );
}
