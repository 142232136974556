import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Projecttype,
  useProjecttypesQuery,
} from "components/fetch/useProjecttypesQuery";
import {
  ContextualLayer,
  useContextualLayersQuery,
} from "components/fetch/useContextualLayersQuery";
import { slugify } from "utils/slugify";
import { Project } from "utils/types/django";
import { replaceText } from "utils/replaceText";

export type CheckStates = {
  [key: string]: boolean;
};

export type BaseLayerState = {
  checkStates: CheckStates;
  toggleCheckStates: (key: string) => void;
  toggleAllCheckStates: (checked: boolean) => void;
};

// Builds data and functions to render layer's cascaded checkbox
const useLayerBuilder = (
  validKeys: string[] | undefined,
  defaultChecked: boolean = false
): BaseLayerState => {
  const [checkStates, setCheckStates] = useState({} as CheckStates);

  // Initialise checkStates with fetched data
  useEffect(() => {
    const initialCheckStates = {} as CheckStates;
    validKeys?.forEach((key) => {
      initialCheckStates[key] = defaultChecked;
    });
    setCheckStates(initialCheckStates);
  }, [defaultChecked, validKeys]);

  const toggleCheckStates = useCallback(
    (key: string) => {
      if (!validKeys?.includes(key)) return; // Make sure using the right key
      setCheckStates((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    },
    [validKeys]
  );

  const toggleAllCheckStates = useCallback((check: boolean) => {
    setCheckStates((prev) => {
      const newCheckStates = { ...prev };
      Object.keys(prev).forEach((key) => {
        newCheckStates[key] = check;
      });
      return newCheckStates;
    });
  }, []);

  return { checkStates, toggleCheckStates, toggleAllCheckStates };
};

export type ProjectLayerState = {
  projectTypes: Projecttype[] | undefined;
} & BaseLayerState;

const useProjectLayerBuilder = (
  layerType: string,
  defaultCheckedState: boolean = true
): ProjectLayerState => {
  const { data } = useProjecttypesQuery();
  const projectTypes = useMemo(() => {
    return data?.filter((item) => item.type.includes(layerType));
  }, [data, layerType]);
  const validKeys = useMemo(
    () => projectTypes?.map((item) => item.name),
    [projectTypes]
  );
  const layerBase = useLayerBuilder(validKeys, defaultCheckedState);
  return { ...layerBase, projectTypes };
};

export type ContextualLayerWithKey = ContextualLayer & { layerKey: string };

export type ContextualLayerGroup = {
  layers: ContextualLayerWithKey[] | undefined;
  name: string;
} & BaseLayerState;

const useContextualLayerBuilder = (key: string) => {
  const { data } = useContextualLayersQuery();
  const contextualLayer = useMemo(
    () => data?.find((item) => item.name === key),
    [data, key]
  );
  const { validKeys, layers } = useMemo(() => {
    const validKeys: string[] = [];
    const layers: ContextualLayerWithKey[] = [];
    contextualLayer?.layers?.forEach((layer) => {
      // Create key based on layer group name + layer name
      // Store it in the layer data too to use it in various places as the layer id
      const layerKey = slugify(`${key} ${layer.name}`);
      validKeys.push(layerKey);
      layers.push({ ...layer, layerKey });
    });
    return { validKeys, layers };
  }, [contextualLayer?.layers, key]);
  const layerBase = useLayerBuilder(validKeys);
  return { ...layerBase, layers, name: key };
};

interface LayersContextInterface {
  projectLayers: {
    horizontalLayers: ProjectLayerState;
    verticalLayers: ProjectLayerState;
    eventLayers: ProjectLayerState;
    layerVisibility: CheckStates;
  };
  completedProjectLayers: {
    horizontalLayers: ProjectLayerState;
    verticalLayers: ProjectLayerState;
    eventLayers: ProjectLayerState;
    layerVisibility: CheckStates;
  };
  contextualLayers: {
    national: ContextualLayerGroup;
    auckland: ContextualLayerGroup;
    wellington: ContextualLayerGroup;
    queenstown: ContextualLayerGroup;
    newPlymouth: ContextualLayerGroup;
    layerVisibility: CheckStates;
  };
  hasProjectLayersVisible: boolean;
  hasCompletedProjectLayersVisible: boolean;
  showProjectPopup: boolean;
  toggleShowProjectPopup: () => void;
  isProjectTypeLayerVisible: (project: Project) => boolean;
}

export const LayersContext = createContext<LayersContextInterface>(null!);

export const LayersProvider = ({ children }: { children: React.ReactNode }) => {
  // Project layers
  const horizontalLayers = useProjectLayerBuilder("Horizontal");
  const verticalLayers = useProjectLayerBuilder("Vertical");
  const eventLayers = useProjectLayerBuilder("Planned Events");
  const projectLayerVisibility = useMemo(
    () => ({
      ...horizontalLayers.checkStates,
      ...verticalLayers.checkStates,
      ...eventLayers.checkStates,
    }),
    [
      eventLayers.checkStates,
      horizontalLayers.checkStates,
      verticalLayers.checkStates,
    ]
  );

  // Completed Project Layers
  const completedHorizontalLayers = useProjectLayerBuilder("Horizontal", false);
  const completedVerticalLayers = useProjectLayerBuilder("Vertical", false);
  const completedEventLayers = useProjectLayerBuilder("Planned Events", false);
  const completedProjectLayerVisibility = useMemo(
    () => ({
      ...completedHorizontalLayers.checkStates,
      ...completedVerticalLayers.checkStates,
      ...completedEventLayers.checkStates,
    }),
    [
      completedEventLayers.checkStates,
      completedHorizontalLayers.checkStates,
      completedVerticalLayers.checkStates,
    ]
  );

  // Contextual layers
  // They are in separate variables in order to guarantee the display order in ContextualLayer.tsx
  const national = useContextualLayerBuilder("National");
  const auckland = useContextualLayerBuilder("Auckland");
  const wellington = useContextualLayerBuilder("Wellington");
  const queenstown = useContextualLayerBuilder("Queenstown");
  const newPlymouth = useContextualLayerBuilder("New Plymouth");
  const contextualLayerVisibility = useMemo(
    () => ({
      ...national.checkStates,
      ...auckland.checkStates,
      ...wellington.checkStates,
      ...queenstown.checkStates,
      ...newPlymouth.checkStates,
    }),
    [
      auckland.checkStates,
      national.checkStates,
      newPlymouth.checkStates,
      queenstown.checkStates,
      wellington.checkStates,
    ]
  );

  const hasProjectLayersVisible = Object.values(projectLayerVisibility).some(
    (visible: boolean) => !!visible
  );
  const hasCompletedProjectLayersVisible = Object.values(
    completedProjectLayerVisibility
  ).some((visible: boolean) => !!visible);

  const [showProjectPopup, setShowProjectPopup] = useState<boolean>(true);
  const toggleShowProjectPopup = () => {
    setShowProjectPopup((prev) => !prev);
  };

  const isProjectTypeLayerVisible = (project: Project) => {
    const layerVisibility =
      project.state.toLowerCase() === "complete"
        ? completedProjectLayerVisibility
        : projectLayerVisibility;
    const infrastructureType = replaceText(
      project.infrastructure_type.toLowerCase(),
      [
        { from: "telecommunications", to: "telecom" },
        { from: "public events", to: "events" },
      ]
    );

    return layerVisibility[infrastructureType];
  };

  const value = {
    projectLayers: {
      horizontalLayers,
      verticalLayers,
      eventLayers,
      layerVisibility: projectLayerVisibility,
    },
    completedProjectLayers: {
      horizontalLayers: completedHorizontalLayers,
      verticalLayers: completedVerticalLayers,
      eventLayers: completedEventLayers,
      layerVisibility: completedProjectLayerVisibility,
    },
    contextualLayers: {
      national,
      auckland,
      wellington,
      queenstown,
      newPlymouth,
      layerVisibility: contextualLayerVisibility,
    },
    hasProjectLayersVisible,
    hasCompletedProjectLayersVisible,
    showProjectPopup,
    toggleShowProjectPopup,
    isProjectTypeLayerVisible,
  };
  return (
    <LayersContext.Provider value={value}>{children}</LayersContext.Provider>
  );
};

export const useLayers = () => {
  return useContext(LayersContext);
};
