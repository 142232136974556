import {
  WatchlistSummary,
  useWatchlists,
} from "components/watchlist/WatchlistContext";
import { WatchlistListItem } from "./WatchlistListItem";
import { ReactComponent as BookmarkIcon } from "assets/other-icons/bookmark.svg";
import { LoadingModal } from "components/loading/LoadingModal";

export const MyWatchlists = () => {
  const { isLoading, bookmarks, savedSearchWatchlists } = useWatchlists();
  const showHelpText =
    !isLoading &&
    bookmarks.projects === 0 &&
    savedSearchWatchlists.length === 0;

  return (
    <>
      <h3 className="h5">My Watchlists</h3>
      {!isLoading && (
        <div className="list-group">
          <WatchlistListItem key="bookmarks" watchlist={bookmarks} />
          {savedSearchWatchlists.map((watchlist: WatchlistSummary) => (
            <WatchlistListItem
              key={`watchlist-${watchlist.name}`}
              watchlist={watchlist}
            />
          ))}
        </div>
      )}
      {showHelpText && (
        <div className="alert alert-primary mt-3" role="alert">
          <p>
            Projects you have added to a watchlist will show up here. You can
            add a project to your Bookmarked Projects list by clicking the{" "}
            <BookmarkIcon className="icon-bookmark" width={15} height={15} />{" "}
            button on the project summary page.
          </p>
          <p>
            If you create any watchlists from Saved Searches, they will also
            appear here, listed under the Saved Search name.
          </p>
          {/* TODO: display this helper text once notifications have been
          implemented */}
          {/* <p>
            You are able to receive notifications on watchlisted projects -
            turn them on or off on the individual watchlist page.
          </p> */}
        </div>
      )}
      {isLoading && <LoadingModal />}
    </>
  );
};
