import { ReactComponent as SearchIcon } from "assets/sidebar-icons/search.svg";
import { Path } from "utils/constants/paths";
import {
  FormSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";
import { useDrawLayer } from "components/drawLayer/DrawLayerProvider";
import { PanelID } from "components/panel/PanelsProvider";
import { NavBarButton } from "./NavBarButton";

const panelId = PanelID.SearchForm;
const path = Path.Search;

export const Search = () => {
  const { cancelFormSearch, formSearchStatus } = useSearchedProjects();
  const { endDrawingSession } = useDrawLayer();

  return (
    <NavBarButton
      component={SearchIcon}
      path={path}
      panelId={panelId}
      data-testid="search-icon"
      style={{ color: "#FFFFFF" }}
      onClick={() => {
        if (formSearchStatus !== FormSearchStatus.Inactive) {
          cancelFormSearch();
        }
        endDrawingSession();
      }}
    />
  );
};
