import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { fetcher } from "components/fetch/axiosFetcher";
import { slugify } from "utils/slugify";
import { ContextualLayerWithKey } from "components/layers/LayersProvider";

export const ESRIMapServerLegend = ({
  layer,
}: {
  layer: ContextualLayerWithKey;
}) => {
  const legendUrl = layer.base_url.replace(/\d+$/, "legend?f=pjson");
  const layerId = layer.base_url.substring(layer.base_url.lastIndexOf("/") + 1);

  const { data } = useQuery([legendUrl], () => fetcher(legendUrl), {
    staleTime: Infinity,
    enabled: !!legendUrl,
  });

  const legend = useMemo(
    () =>
      data?.layers?.find((item: any) => item.layerId?.toString() === layerId)
        ?.legend,
    [data?.layers, layerId]
  );

  if (!legend || !Array.isArray(legend) || legend.length < 1) {
    return (
      <div className="ms-3 text-small text-danger fst-italic">
        Legends data not provided
      </div>
    );
  }

  return (
    <>
      {legend.map((legend, i) => (
        <div
          key={slugify(`${layer.layerKey} ${i}`)}
          className="ms-3 text-small fst-italic d-flex"
        >
          <img
            src={`data:image/png;base64,${legend.imageData}`}
            width={20}
            height={20}
            alt={legend.label || `Legend for ${layer.layerKey}`}
            className="me-2"
            style={{ flexBasis: 20 }}
          />
          <span>{legend.label || ""}</span>
        </div>
      ))}
    </>
  );
};
