import { useQuery } from "@tanstack/react-query";
import {
  Renderer,
  ClassBreaksRenderer,
  UniqueValueRenderer,
  SimpleRenderer,
} from "esri-leaflet-renderers";

import { fetcher } from "components/fetch/axiosFetcher";
import { slugify } from "utils/slugify";
import { ContextualLayerWithKey } from "components/layers/LayersProvider";

export const ESRIFeatureServerLegend = ({
  layer,
}: {
  layer: ContextualLayerWithKey;
}) => {
  const url = layer.base_url + "?f=pjson";
  const { data, isLoading } = useQuery([url], () => fetcher(url), {
    staleTime: Infinity,
    enabled: !!url,
  });
  if (isLoading) {
    return <div className="ms-3 text-small fst-italic">Loading...</div>;
  }

  const rendererInfo = data?.drawingInfo?.renderer;
  if (!rendererInfo) return null;

  const options = {
    layerTransparency: data?.drawingInfo?.transparency ?? 0,
  };
  let renderer: Renderer;
  switch (rendererInfo?.type) {
    case "classBreaks":
      renderer = new ClassBreaksRenderer(rendererInfo, options);
      break;
    case "uniqueValue":
      renderer = new UniqueValueRenderer(rendererInfo, options);
      break;
    default:
      renderer = new SimpleRenderer(rendererInfo, options);
  }
  if (!Array.isArray(renderer._symbols) || renderer._symbols.length < 1) {
    return (
      <div className="ms-3 text-small text-danger fst-italic">
        This layer has no legend data
      </div>
    );
  }

  return (
    <>
      {renderer._symbols.map((symbol, i) => {
        const styles = symbol._styles;
        let labelText = symbol.val;

        // Special filtering for QLDC. It relies on the renderer being set to type="uniqueValue".
        // It's flaky and might not work for other data source's filtering in the future,
        // but it's okay for now as QLDC is the only one that needs this filtering.
        const typeId =
          layer.feature_filter?.filterFieldName && layer.feature_filter?.typeId;
        if (typeof typeId === "number") {
          if (symbol.val !== typeId.toString()) {
            return null;
          }
          labelText = renderer._rendererJson?.uniqueValueInfos?.find(
            (item: any) => item.value === symbol.val
          )?.label; // If not found it's fine, there's fallback in JSX
        }

        let fillColor: string = styles?.color || "transparent"; // Defaults to styles.color
        if (styles?.fillColor) {
          if (styles.fillColor === "rgb(0,0,0)") {
            // With some trials, this was the most reliable way to determine
            // transparent fill. Not all of them have `styles.fillOpacity = 0`
            // e.g. Queenstown - Overlay polygons protected trees
            fillColor = "transparent";
          } else {
            fillColor = styles.fillColor;
          }
        }
        const svgStyle = {
          fill: fillColor,
          opacity: styles.fillOpacity || 1,
          stroke: styles.color || "transparent",
          strokeWidth: styles.weight || 1,
          strokeOpacity: styles.opacity || 1,
          strokeDasharray: styles.dashArray?.replaceAll(",", " ") || undefined,
        };

        return (
          <div
            key={slugify(`${layer.layerKey} ${i}`)}
            className="ms-3 text-small fst-italic d-flex"
          >
            <div
              className="me-2"
              style={{ width: 20, height: 20, flexBasis: 20 }}
            >
              {symbol._iconUrl && (
                <img
                  src={symbol._iconUrl}
                  width={20}
                  height={20}
                  alt={symbol.val || `Legend for ${layer.layerKey}`}
                />
              )}
              {!symbol._iconUrl && (
                <svg width={20} height={20}>
                  {styles?.radius ? (
                    <circle cx="50%" cy="50%" r="30%" style={svgStyle} />
                  ) : (
                    <rect
                      width={20}
                      height={styles?.fill ? 20 : 1}
                      y={styles?.fill ? 0 : 9}
                      style={svgStyle}
                    />
                  )}
                </svg>
              )}
            </div>
            <span>{labelText || ""}</span>
          </div>
        );
      })}
    </>
  );
};
