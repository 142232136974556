import { useState, useEffect, useRef } from "react";
import { ProjectLink } from "components/project/ProjectLink";
import { PublishedProjectsTablePagination } from "./PublishedProjectsTablePagination";
import {
  MyOrganisationsProjectsSearchStatus,
  useMyOrganisationsProjects,
} from "./MyOrganisationsProjectsContext";
import { useMyOrganisation } from "components/fetch/useMyOrganisation";
import { defaultFilterValue } from "./MyOrganisationFilterContext";
import { LoadingModal } from "components/loading/LoadingModal";

export const PublishedProjectsTable = () => {
  const {
    myOrganisationsProjectsSearchStatus,
    searchWithFilter,
    searchWithPageNumber,
    currentPageResults,
  } = useMyOrganisationsProjects();
  const firstRenderRef = useRef(false);
  const organisation = useMyOrganisation();

  // Paginate the request based on the page number in the web app's URL:
  const params = new URLSearchParams(document.location.search);
  const pageParam = params.get("page");
  const pageParamInt = parseInt(pageParam || "1", 10);
  const [currentPageNumber, setCurrentPageNumber] =
    useState<number>(pageParamInt);

  useEffect(() => {
    if (
      !pageParam ||
      myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.InProgress
    )
      return;
    if (pageParamInt !== currentPageNumber) {
      setCurrentPageNumber(pageParamInt);
      searchWithPageNumber(pageParamInt);
    }
  }, [
    currentPageNumber,
    pageParamInt,
    pageParam,
    myOrganisationsProjectsSearchStatus,
    searchWithPageNumber,
  ]);

  useEffect(() => {
    // The first time that the page table is loaded, search with the default
    // filter. Subsequent searches will happen when the filter form is
    // submitted:
    if (
      organisation &&
      myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.NeverRun
    ) {
      if (!firstRenderRef.current) {
        searchWithFilter(defaultFilterValue);
        firstRenderRef.current = true;
      }
    }
  }, [organisation, searchWithFilter, myOrganisationsProjectsSearchStatus]);

  const projects = currentPageResults?.results ?? [];

  return (
    <>
      {/* Show a loading modal only when the first page of projects is being loaded: */}
      {myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.InProgress &&
        (currentPageResults?.results.length || 0) < 1 && <LoadingModal />}
      {myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.Inactive &&
        (currentPageResults?.results.length || 0) < 1 && (
          <div>No published projects found.</div>
        )}
      {myOrganisationsProjectsSearchStatus ===
        MyOrganisationsProjectsSearchStatus.Inactive &&
        (currentPageResults?.results.length || 0) > 0 && (
          <table className="table table-sm table-hover text-small w-100">
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    <ProjectLink project={project} flyTo />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      <PublishedProjectsTablePagination
        totalProjectsCount={currentPageResults?.count || 0}
        currentPageNumber={currentPageNumber}
      />
    </>
  );
};
