import geojson from "geojson";

export type PaginatedResults<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ReadonlyArray<T>;
};

export type Organisation = {
  id: number;
  name: string;
  address: string;
};

export type Contact = {
  name: string;
  email: string;
  phone_num: string;
};

export type Coordinate = [number, number];
export type Polygon = Coordinate[];

export type Impact = {
  id?: number;
  impact_type: string;
  time_of_effect: string;
};

export type Stage = {
  id: number;
  start_date: string;
  end_date: string;
  last_updated: string;
  title: string;
  description: string;
  shape: geojson.FeatureCollection<geojson.Polygon, any>;
  impacts: Impact[];
  clashes: ProjectOverview[];
  opportunities: ProjectOverview[];
};

export type Project = {
  id: number;
  organisation: Organisation;
  last_updated_by: string | null;
  last_updated: string;
  contact: Contact | null;
  project_type: string;
  stages: Stage[];
  reference: string;
  title: string;
  is_published: boolean;
  details: string;
  infrastructure_type: string;
  state: string;
  last_updated_reason: string;

  start_date: string;
  end_date: string;

  is_multistage: boolean;
  is_bookmarked: boolean;
};

export type ProjectOverview = {
  id: number;
  title: string;
  organisation: Organisation;
  start_date: string;
  end_date: string;
};

export type Region = {
  id: number;
  name: string;
};

export enum SystemRole {
  BusinessUser = "Business user",
  ProjectPlanner = "Project planner",
  Superuser = "Superuser",
}

export type User = {
  id: number;
  email: string;
  full_name: string;
  system_role: SystemRole;
  region: Region;
  organisation: Organisation;
  position: string;
  phone_num?: string;
};

export enum ProjectType {
  horizontal = "Horizontal Construction",
  vertical = "Vertical Construction",
  events = "Planned Events",
}

export type InfrastructureType =
  | "electricity"
  | "gas"
  | "transport"
  | "three waters"
  | "telecom"
  | "greenspace"
  | "residential"
  | "commercial"
  | "mixed"
  | "education"
  | "recreation"
  | "healthcare"
  | "government"
  | "other"
  | "events"
  | "roadworks";

export type State =
  | "PLANNED"
  | "IN_PROGRESS"
  | "STALLED"
  | "DEFERRED"
  | "COMPLETE";
