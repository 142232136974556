import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useMatchPath } from "utils/path";
import { Path } from "utils/constants/paths";
import { VisuallyHidden } from "components/layout/VisuallyHidden";
import { ProjectSummaryForm } from "./ProjectSummaryForm";
import { ProjectDetailForm } from "./ProjectDetailForm";
import { useMyOrganisationProjectsQuery } from "components/fetch/useProjectsQuery";
import { useDrawLayer, DrawMode } from "components/drawLayer/DrawLayerProvider";
import { DATE_FORMAT } from "utils/constants/date";
import { useErrorModal } from "components/modal/useErrorModal";
import { useProject } from "components/project/ProjectProvider";
import { PanelBody } from "../PanelBody";
import { NavPromptModal } from "components/navBlocker/NavPromptModal";
import { useNavPrompt } from "components/navBlocker/useNavPrompt";
import { useWatchlists } from "components/watchlist/WatchlistContext";
import { LoadingModal } from "components/loading/LoadingModal";
import { PanelID, usePanels } from "components/panel/PanelsProvider";

export const CreateProjectPanel = () => {
  const [page, setPage] = useState(0);
  const [pageValues, setPageValues] = useState([] as any[]);
  const [isMultiStage, setIsMultiStage] = useState(false);
  const [isSaving, setisSaving] = useState(false);

  const { refetch } = useMyOrganisationProjectsQuery();
  const { invalidateWatchlistQueries } = useWatchlists();
  const { geoJson, setGeoJson, setDrawMode } = useDrawLayer();
  const { setErrorModal } = useErrorModal();
  const { createProject } = useProject();

  const isOnAddProjectPath = useMatchPath(Path.AddProject);

  const { expandPanel, closePanel, isClosed } = usePanels();

  // Only enter draw polygon mode on details panel
  useEffect(() => {
    if (page === 1) {
      setDrawMode(DrawMode.CreateStageBoundary);
    } else {
      setDrawMode(DrawMode.Inactive);
    }
    return () => {
      setDrawMode(DrawMode.Inactive);
    };
  }, [page, setDrawMode]);

  // Clean up saved GeoJSON on unmount
  useEffect(() => {
    return () => {
      setGeoJson(null);
    };
  }, [setGeoJson]);

  const { shouldPrompt, forceNavigate } = useNavPrompt();
  const onSubmit = async (values: any) => {
    const newPageValues: any[] = [...pageValues];
    newPageValues[page] = values;

    if (!geoJson || !geoJson.features || geoJson.features.length < 1) {
      setErrorModal({
        title: "No boundary set",
        content: "Please add at least one boundary before saving.",
      });
      return;
    }

    setisSaving(true);

    try {
      const [projectStartDate, projectEndDate] =
        newPageValues[0].projectDuration;
      const [stageStartDate, stageEndDate] = newPageValues[1].stageDuration;

      await createProject({
        title: newPageValues[0].projectName,
        details: newPageValues[0].projectDetails,
        reference: newPageValues[0].reference,
        infrastructure_type: newPageValues[0].infrastructureType.toLowerCase(),
        start_date: format(
          isMultiStage
            ? new Date(Math.min(projectStartDate, stageStartDate))
            : projectStartDate,
          DATE_FORMAT
        ),
        end_date: format(
          isMultiStage
            ? new Date(Math.max(projectEndDate, stageEndDate))
            : projectEndDate,
          DATE_FORMAT
        ),
        contact: {
          name: newPageValues[0].contactName,
          email: newPageValues[0].contactEmail,
          phone_num: newPageValues[0].contactPhone,
        },
        stages: [
          {
            title: isMultiStage
              ? newPageValues[1].stageName
              : newPageValues[0].projectName,
            description: isMultiStage ? newPageValues[1].stageDescription : "",
            start_date: format(
              isMultiStage ? stageStartDate : projectStartDate,
              DATE_FORMAT
            ),
            end_date: format(
              isMultiStage ? stageEndDate : projectEndDate,
              DATE_FORMAT
            ),
            shape: geoJson,
            impacts: newPageValues[1].trafficImpacts.map(
              (impact: { type: string; timeframe: string }) => ({
                impact_type: impact.type,
                time_of_effect: impact.timeframe,
              })
            ),
          },
        ],
        is_multistage: isMultiStage,
      });
      refetch();
      invalidateWatchlistQueries();
      forceNavigate("/my-organisation");
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setisSaving(false);
    }
  };

  const onNext = (values: any) => {
    const newPageValues: any[] = [...pageValues];
    newPageValues[page] = values;
    setPageValues(newPageValues);
    setPage(page + 1);
  };

  const onBack = (values: any) => {
    const newPageValues: any[] = [...pageValues];
    newPageValues[page] = values;
    setPageValues(newPageValues);
    setPage(page - 1);
  };

  useEffect(() => {
    if (isOnAddProjectPath && isClosed(PanelID.AddProject)) {
      expandPanel(PanelID.AddProject);
    }
  }, [closePanel, expandPanel, isClosed, isOnAddProjectPath]);

  return (
    <PanelBody>
      <h2 className="h3">
        {page === 1 ? "Add project boundary" : "Create project"}
      </h2>

      {isSaving && <LoadingModal />}

      <VisuallyHidden when={page !== 0}>
        <ProjectSummaryForm onNext={onNext} setIsMultiStage={setIsMultiStage} />
      </VisuallyHidden>

      <VisuallyHidden when={page !== 1}>
        <ProjectDetailForm
          pageValues={pageValues}
          onBack={onBack}
          onSubmit={onSubmit}
          isMultiStage={isMultiStage}
        />
      </VisuallyHidden>

      <NavPromptModal
        enabled={shouldPrompt}
        title="Are you sure?"
        content="Do you want to close create project form? Unsaved project will be lost."
      />
    </PanelBody>
  );
};
