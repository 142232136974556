import { useState, useEffect } from "react";
import { LeftHeaderTable, LeftHeaderRow } from "components/table";
import { ProjectLink } from "components/project/ProjectLink";
import { Project } from "utils/types/django";
import { ProjectListPagination } from "./ProjectListPagination";
import {
  FormSearchStatus,
  PointSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";

export const ProjectList = ({
  projects,
  totalProjectsCount,
  isAreaSearch,
  children,
}: {
  projects: Project[];
  totalProjectsCount?: number;
  isAreaSearch: boolean;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const {
    isLoadingFirstPage,
    pointSearchStatus,
    searchWithPageNumber,
    formSearchStatus,
  } = useSearchedProjects();

  // Paginate the request based on the page number in the web app's URL:
  const params = new URLSearchParams(document.location.search);
  const pageParam = params.get("page");
  const paramParamInt = parseInt(pageParam || "1", 10);
  const [currentPageNumber, setCurrentPageNumber] =
    useState<number>(paramParamInt);

  useEffect(() => {
    if (
      !pageParam ||
      isLoadingFirstPage ||
      formSearchStatus === FormSearchStatus.InProgress ||
      pointSearchStatus !== PointSearchStatus.Inactive
    )
      return;
    if (paramParamInt !== currentPageNumber) {
      setCurrentPageNumber(paramParamInt);
      searchWithPageNumber(paramParamInt);
    }
  }, [
    currentPageNumber,
    paramParamInt,
    searchWithPageNumber,
    pageParam,
    isLoadingFirstPage,
    pointSearchStatus,
    formSearchStatus,
  ]);

  return (
    <>
      {/* allow for custom warning messages etc. */}
      {children}

      {formSearchStatus === FormSearchStatus.InProgress && (
        <div>
          There are {totalProjectsCount} results to display, please wait whilst
          they are loaded. You may want to refine your search criteria.
        </div>
      )}

      {formSearchStatus === FormSearchStatus.Inactive &&
        projects.length > 0 &&
        projects.map((project: Project) => (
          <div key={project.id} className="mb-3">
            <div className="mb-1">
              <ProjectLink project={project} flyTo />
            </div>
            <div className="mb-1">
              <LeftHeaderTable>
                <LeftHeaderRow
                  header="Organisation"
                  data={project.organisation.name}
                />
                <LeftHeaderRow
                  header="Org Reference"
                  data={project.reference}
                />
                <LeftHeaderRow
                  header="Type"
                  data={
                    (project.project_type === "Planned Events"
                      ? project.project_type
                      : project.project_type.split(" ")[0]) +
                    " - " +
                    project.infrastructure_type
                  }
                />
                <LeftHeaderRow header="Start date" data={project.start_date} />
                <LeftHeaderRow header="End date" data={project.end_date} />
                <LeftHeaderRow header="State" data={project.state} />
              </LeftHeaderTable>
            </div>
            <div className="text-small">{project.details}</div>
          </div>
        ))}
      {!isAreaSearch && (
        <ProjectListPagination
          totalProjectsCount={totalProjectsCount ?? projects.length}
          currentPageNumber={currentPageNumber}
        />
      )}
    </>
  );
};
