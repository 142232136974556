import classNames from "classnames";
import { VisuallyHidden } from "components/layout/VisuallyHidden";
import { CloseButton } from "./CloseButton";
import { ReactComponent as ChevronsRight } from "assets/other-icons/angles-right-solid.svg";
import { ReactComponent as ChevronLeft } from "assets/other-icons/angle-left-solid.svg";
import { usePanels, PanelID } from "components/panel/PanelsProvider";

/**
 * A version of PanelBody with chevrons for expanding/collapsing the panel. It
 * handles it's own expanding/collapsing based on the panel ID that is passed to
 * it as a prop.
 */
export const CollapsiblePanelBody = ({
  panelId,
  expandedTitle,
  collapsedTitle,
  afterExpand,
  afterCollapse,
  isCollapsible = true,
  hideCloseButton = false,
  onClose,
  children,
}: {
  panelId: PanelID;
  expandedTitle: string;
  collapsedTitle?: string; // Will use the expandedTitle instead if not present
  afterExpand?: () => void;
  afterCollapse?: () => void;
  isCollapsible?: boolean;
  hideCloseButton?: boolean;
  onClose?: (() => void) | null;
  children: React.ReactNode;
}) => {
  const { isCollapsed, expandPanel, collapsePanel } = usePanels();

  return (
    <>
      <div
        className={classNames("panel-body-wrapper", {
          minimised: isCollapsed(panelId),
        })}
      >
        <VisuallyHidden when={isCollapsed(panelId)}>
          <div className="panel-body vh-100 overflow-y-scroll p-3">
            <>
              {!isCollapsed(panelId) && (
                <h2 className="h3">
                  {expandedTitle}
                  {isCollapsible && (
                    <ChevronLeft
                      type="button"
                      width={20}
                      height={25}
                      className="btn-collapse"
                      aria-label="Collapse panel"
                      onClick={() => {
                        collapsePanel(panelId);
                        if (afterCollapse) {
                          afterCollapse();
                        }
                      }}
                    />
                  )}
                </h2>
              )}
              {children}
            </>
          </div>
        </VisuallyHidden>
        {isCollapsed(panelId) && (
          <div className="p-3 vh-100">
            <ChevronsRight
              type="button"
              width={24}
              height={38}
              className="btn-expand"
              aria-label="Expand panel"
              onClick={() => {
                expandPanel(panelId);
                if (afterExpand) {
                  afterExpand();
                }
              }}
            />
            <span className="collapsed-title">
              {collapsedTitle ? collapsedTitle : expandedTitle}
            </span>
          </div>
        )}
      </div>
      {!hideCloseButton && <CloseButton onClick={onClose} />}
    </>
  );
};
