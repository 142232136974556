import { useEffect, useState } from "react";
import { EmailIconButton } from "./EmailIconButton";
import {
  WatchlistSummary,
  bookmarksId,
  bookmarksName,
  useWatchlists,
} from "components/watchlist/WatchlistContext";

export interface WatchlistNotificationButtonProps {
  watchlist: WatchlistSummary;
}

export const WatchlistNotificationsButton = ({
  watchlist,
}: WatchlistNotificationButtonProps) => {
  const [checked, setChecked] = useState(watchlist.notifications);
  const { enableNotifications, disableNotifications } = useWatchlists();

  useEffect(() => {
    setChecked(watchlist.notifications);
  }, [watchlist.notifications]);

  return (
    <EmailIconButton
      checked={checked}
      title={
        checked
          ? "Turn off weekly notifications"
          : "Turn on weekly notifications"
      }
      width={30}
      height={30}
      onClick={() => {
        const isBookmarks = watchlist.name === bookmarksName;
        const id = isBookmarks ? bookmarksId : watchlist.id;
        if (checked) {
          disableNotifications(id);
        } else {
          enableNotifications(id);
        }
        setChecked((c) => !c);
      }}
    />
  );
};
