import { useEffect, useCallback, useState } from "react";
import { useMapEvent } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import {
  PointSearchStatus,
  useSearchedProjects,
} from "components/project/searched-projects/SearchedProjectsContext";
import { useSelectedProject } from "components/project/useSelectedProject";
import { useDrawLayer } from "components/drawLayer/DrawLayerProvider";
import {
  PanelID,
  PanelVisibility,
  usePanels,
} from "components/panel/PanelsProvider";
import { useLayers } from "components/layers/LayersProvider";

enum URLRoot {
  Projects = "/projects",
  Search = "/search",
}

export const MapClickHandler = () => {
  const navigate = useNavigate();
  const { navigateToProject } = useSelectedProject();
  const { isDrawingAllowed } = useDrawLayer();
  const {
    formSearchParams,
    formSearchResults,
    pointSearchResults,
    point,
    searchPointForOverlappingResults,
    pointSearchStatus,
    completePointSearch,
  } = useSearchedProjects();
  const {
    expandPanel,
    isCollapsed,
    someAreOpen,
    someSearchPanelsAreOpen,
    setPanelVisibilities,
  } = usePanels();

  const hasSearchedProjects = (formSearchResults?.count ?? 0) > 0;

  const [urlRoot, setUrlRoot] = useState<URLRoot>(URLRoot.Projects);

  const { hasProjectLayersVisible, hasCompletedProjectLayersVisible } =
    useLayers();

  // Only when a point search is completed should we navigate to a new URL:
  useEffect(() => {
    if (pointSearchStatus === PointSearchStatus.ReadyToNavigate) {
      const project =
        pointSearchResults?.count === 1 ? pointSearchResults.results[0] : null;

      // If there is only a single project, navigate to it, otherwise show the
      // search results or projects in area:
      if (project) {
        navigateToProject(project.id, true);
      } else {
        // TODO: navigate only if there's an intersecting vector grid at the cursor
        if (point) {
          // If the "Search Results in area" panel is collapsed, we can assume
          // that the change in point means that the user wants to expand the
          // panel to see the new set of search results in area:
          if (isCollapsed(PanelID.SearchResultsInArea)) {
            expandPanel(PanelID.SearchResultsInArea);
          }

          navigate(`${urlRoot}/${point.lng}/${point.lat}`);
        }
      }

      // After navigating, complete the search so that we can navigate to new
      // points:
      completePointSearch();
    }
  }, [
    pointSearchStatus,
    completePointSearch,
    pointSearchResults,
    navigateToProject,
    navigate,
    urlRoot,
    point,
    isCollapsed,
    expandPanel,
  ]);

  /**
   * Construct the search params that are exclusive to a "Search results in
   * area" point search. These should be based on the form search results and
   * should not factor in visible layers. Do not use useMemo here as we want to
   * ensure that new params are generated on each point click.
   */
  const getSearchResultsInAreaPointSearchParams = useCallback((): FormData => {
    /**
     * Create a copy of the form search params to use as the basis for the
     * point search params so that we're also filtering by the search
     * criteria.
     *
     * Append a new intersects parameter with just the point coordinates,
     * replacing this each time that we perform a new point search so that the
     * "Search results in area" panel only displays results for the existing
     * form search and the specific point, but not previously clicked points.
     *
     * The backend supports multiple intersects parameters. It's intended to
     * be general purpose and extensible to as many geometries as necessary.
     */
    const params = new FormData();
    formSearchParams.forEach((value, key) => params.append(key, value));
    return params;
  }, [formSearchParams]);

  /**
   * Construct the search params that are exclusive to a "Projects in area"
   * point search. These should not be based on the form search results and
   * should factor in visible layers. Do not use useMemo here as we want to
   * ensure that new params are generated on each point click.
   */
  const getProjectsInAreaPointSearchParams = useCallback((): FormData => {
    const params = new FormData();
    params.append("order_by", "most_recent");
    params.append("page_size", "100");

    // speed up query if only complete/incomplete projects are visible
    if (hasCompletedProjectLayersVisible && !hasProjectLayersVisible) {
      params.append("state", "COMPLETE");
    } else if (hasProjectLayersVisible && !hasCompletedProjectLayersVisible) {
      params.append("state", "PLANNED");
      params.append("state", "IN_PROGRESS");
      params.append("state", "DEFERRED");
      params.append("state", "STALLED");
    }
    return params;
  }, [hasCompletedProjectLayersVisible, hasProjectLayersVisible]);

  useMapEvent("click", (e: any) => {
    // If the user is currently allowed to draw, prevent any further click
    // handling so that these operations don't conflict:
    if (isDrawingAllowed) return;

    // Don't click through other elements on the screen.
    // Note: The below if statement was previously using originalEvent.explicitOriginalTarget
    // rather than originalTarget.srcElement, however this is not recommended and doesn't work
    // outside Firefox:
    // https://developer.mozilla.org/en-US/docs/Web/API/Event/explicitOriginalTarget
    if (!e.originalEvent.srcElement.classList.contains("leaflet-tile")) {
      return;
    }

    // If the map is clicked and there are no searched projects, close the
    // search panels entirely so that the user can leave search mode and focus
    // on projects etc.
    if (!hasSearchedProjects && someSearchPanelsAreOpen) {
      setPanelVisibilities([
        { id: PanelID.SearchForm, visibility: PanelVisibility.Closed },
        { id: PanelID.SearchResults, visibility: PanelVisibility.Closed },
      ]);
    }

    // Go to /search/:lng/:lat path when in the middle of a form search (not
    // just the Search Form panel being open, but only after a search has been
    // run), otherwise go to /projects/:lng/:lat.
    // This ensures that we get "Search results in area" only when a form search
    // has been submitted.
    const basePointSearchOnFormSearch = someAreOpen([
      PanelID.SearchResults,
      PanelID.SearchResultsInArea,
    ]);
    setUrlRoot(basePointSearchOnFormSearch ? URLRoot.Search : URLRoot.Projects);

    if (point !== e.latlng) {
      // Starts a new point search that will navigate after that data is
      // returned for the point:
      const pointSearchParams = basePointSearchOnFormSearch
        ? getSearchResultsInAreaPointSearchParams()
        : getProjectsInAreaPointSearchParams();

      searchPointForOverlappingResults(e.latlng, pointSearchParams);
    }
  });

  return null;
};
